body {
  padding: 0;
  margin: 0;
  font-family: "montserrat";
}
section {
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  font-family: "montserrat";
}
h1 {
  font-size: 2.25rem;
  font-weight: 800;
}
.hstack {
  display: flex;
  flex-direction: row;
}
.vstack {
  display: flex;
  flex-direction: column;
}
.vcenter {
  align-items: center;
}
.hcenter {
  margin: 0 auto;
}
.full-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blur-image::after {
  background-color: rgba(0, 0, 0, 0.33);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
button {
  transition: 0.125s;
  cursor: pointer;
}
button:hover {
  transform: scale(0.95);
}
@media screen and (max-width: 800px) {
  .lead h1 {
    font-size: 3em !important;
    max-width: 90% !important;
  }
  .lead h2 {
    font-size: 1.5rem !important;
    max-width: 90% !important;
  }
}
.stats-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  padding-bottom: 2em;
}
.stats-grid div {
  border-radius: 2rem;
  background-color: white;
  padding: 1.5em;
  padding-top: 0.5em;
  min-width: min(20vw, 90%);
  min-height: 12em;
  box-shadow: 0 0.5em 1em 0 #71717a;
}
.stats-grid h1 {
  font-size: 5em;
}
.stats-grid h2 {
  font-weight: 400;
}
.cool-purple-button {
  padding: 0.25em 2em;
  font-size: 1.75em;
  font-weight: 900;
  background-color: #6d28d9;
  margin-left: auto;
  border-radius: 0.33em;
  color: white;
  border: none;
  box-shadow: -3px 3px 30px 10px rgba(109, 40, 217, 0.5);
  font-family: "montserrat", serif !important;
}
.cool-purple-button:hover {
  transform: scale(0.9);
}
.form-item {
  width: 100%;
}
textarea.big {
  height: 100vh;
}
.blog-editor-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 1em 1em;
  max-width: min(100%, 800px);
  grid-gap: 1em 0em;
}
.blog-editor-wrapper label {
  font-size: 1.2em;
  margin-right: auto;
  color: var(--headerbackgroundtext);
}
.blog-editor-wrapper input,
.blog-editor-wrapper textarea {
  border: none;
  width: 100%;
  font-size: 1.5em;
  border-radius: 0.45em;
  padding: 0.25em 0.25em;
  padding-right: 0;
  font-family: "montserrat", serif !important;
  background-color: #e2e8f0;
}
.blog-editor-wrapper textarea {
  height: 7em;
}
.blog-editor-wrapper a:hover {
  color: rgb(233, 30, 99);
}
.alert-wrapper {
  position: fixed;
  top: 0;
  margin-top: 1.5em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1em;
  background-color: white;
  box-shadow: 0em 0em 2em 0 black;
  max-width: max-content;
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.33em;
  padding-bottom: 1.2em !important;
  width: 30em;
  max-width: 90% !important;
  color: white;
  align-items: center;
}
.alert-wrapper h1 {
  padding: 0.25em 1em;
  padding-bottom: 0;
  color: black !important;
  font-family: "montserrat", serif !important;
}
.alert-wrapper p {
  text-align: center;
  font-size: 1.25em;
  max-width: 90%;
  margin: 0 auto;
  color: black !important;
}
.alert-wrapper .options-wrapper {
  display: flex;
  justify-content: center;
  gap: 1em;
  width: 90%;
}
.alert-wrapper .options-wrapper button {
  padding: 0.25em 1em;
  max-width: 12em;
  font-family: "montserrat", serif !important;
  font-size: 1.3em;
  border: none;
  width: 100% !important;
  border-radius: 0.25em;
  margin: 0 auto;
  margin-top: 0.5em;
}
footer {
  overflow-x: scroll;
}
footer h1,
footer h2 {
  max-width: 90%;
}
footer a {
  color: white;
}
